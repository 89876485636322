import { UserInfo } from '@/vo/UserInfo'
import { LocalStorageKey } from '@/config/enum'
import { VuexMutationKey } from '@/store/vuexEnum'
import http from '../http'
import { AuthTree } from '@/api/user'
import router from '@/router'
import store from '@/store'
import { getUserInfo } from '../special/getUserInfo'

export const isLogin = function(): boolean {
  return getUserInfo() ? true : false
}

export const setUserInfo = function(userInfo: UserInfo) {
  if (!userInfo) return
  const oldUserInfo = getUserInfo()
  if (oldUserInfo) Object.assign(oldUserInfo, userInfo)
  userInfo = oldUserInfo || userInfo
  localStorage.setItem(LocalStorageKey.USER_INFO, JSON.stringify(userInfo))
  store.commit(VuexMutationKey.updateUserInfo, userInfo)
}

export const setAuths = function(auths: AuthTree[]) {
  if (!auths) return
  store.commit(VuexMutationKey.updateAuths, auths)
}
export const loginOut = function() {
  localStorage.clear()
  sessionStorage.clear()
  store.commit(VuexMutationKey.updateUserInfo, null)
  store.commit(VuexMutationKey.updateMenuList, [])
  store.commit(VuexMutationKey.updateAuths, [])
  if (router) {
    router.push({
      name: 'login',
    })
  }
}

export function updateUserInfo() {
  return http<UserInfo>(
    'GET',
    '/api/student/userInfo',
    {},
    {
      tokenExpireUncheck: true,
      showMsg: false,
    }
  ).then(userInfo => {
    setUserInfo(userInfo)
  })
}
