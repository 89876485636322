import G from '@/config/config'
import http from '@/utils/http'

export type AuthTree = {
  authId: number
  authName: string
  platform: number
  subAuth: AuthTree[]
}

export function apiGetAuth(authId?: number) {
  return http<AuthTree[]>('GET', `/correct/bs/userdomain/auth/query`, {
    platform: G.platform,
    authId: authId,
  })
}
