export enum EnvName {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface NodeEnv {
  NODE_ENV: EnvName
  VUE_APP_BASE_URL: string
  VUE_APP_MOCK_URL: string
  VUE_APP_PLATFORM: number | string
}
