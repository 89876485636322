/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str: string) {
  const pattern = /^[a-zA-Z0-9]{1,20}$/
  return str && str.length <= 20 && pattern.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validPassword(str: string) {
  const pattern = /^[a-zA-Z0-9-_@!#$%^&*]{1,20}$/
  return str && str.length >= 8 && str.length <= 20 && pattern.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validRoleId(id: number) {
  return !!id
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validName(str: string) {
  return str && str.length <= 50
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validRoleName(str: string) {
  return str && str.length <= 40
}
