import { defineComponent, h, RendererElement, RendererNode, VNode } from '@vue/runtime-core'
import { ElMenuItem, ElSubmenu } from 'element-plus'
import _ from 'lodash'
import { RouteRecordRaw } from 'vue-router'
export default defineComponent({
  name: 'MenuItem',
  props: {
    list: {
      type: Array,
      required: true,
      default: () => Array<RouteRecordRaw>(),
    },
  },
  render() {
    function recursionCreateMenuItem(list: Array<RouteRecordRaw>) {
      let arr: VNode<
        RendererNode,
        RendererElement,
        {
          [key: string]: unknown
        }
      >[] = []
      if (list && list.length != 0) {
        list.forEach(route => {
          let isElIcon = /el-icon/.test(route?.meta?.icon || '')
          let icon = route.meta?.icon
            ? h('i', {
                class: isElIcon ? route.meta.icon : 'iconfont',
                innerHTML: isElIcon ? '' : route.meta.icon,
                style: isElIcon
                  ? ''
                  : {
                      fontSize: '18px',
                      verticalAlign: 'middle',
                      marginRight: '5px',
                      marginLeft: '5px',
                      width: '24px',
                      textAlign: 'center',
                    },
              })
            : ''

          let ele = h<{}>(
            ElSubmenu,
            {
              index: route.name || route.path,
            },
            {
              title: () => [
                icon,
                h(
                  'span',
                  {
                    style: {
                      marginLeft: route.meta?.icon ? '5px' : '10px',
                      letterSpacing: '2px',
                    },
                  },
                  route.meta?.title?.toString()
                ),
              ],
              default: () => recursionCreateMenuItem(route.children || []),
            }
          )
          if (!route.children || (route.children && route.children.length == 0)) {
            ele = h(
              ElMenuItem,
              {
                index: route.name || route.path,
                route: route,
                style: {
                  // backgroundColor: '#1F2D3D'
                },
              },
              {
                default: () => [
                  icon,
                  h(
                    'span',
                    {
                      style: {
                        marginLeft: route.meta?.icon ? '5px' : '10px',
                        letterSpacing: '2px',
                      },
                    },
                    route.meta?.title?.toString()
                  ),
                ],
              }
            )
          }
          arr.push(ele)
        })
      } else {
        return []
      }
      return arr
    }
    return recursionCreateMenuItem(_.cloneDeep(this.list as Array<RouteRecordRaw>))
  },
})
