
import store from '@/store'
import { VuexStateKey } from '@/store/vuexEnum'
import { defineComponent } from '@vue/runtime-core'
import Hamburger from './components/Hamburger.vue'
import MenuItem from './components/MenuItem'
import { loginOut } from '@/utils/utils'
import { ElMenu } from 'element-plus'
import { RouteLocationMatched } from 'vue-router'

export default defineComponent({
  components: {
    [Hamburger.name]: Hamburger,
    [MenuItem.name]: MenuItem,
  },
  data() {
    return {
      isCollapse: false,
      levelList: Array<RouteLocationMatched>(),
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },
  created() {
    this.getBreadcrumb()
  },
  mounted() {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: {
    menuList: () => store.state[VuexStateKey.menuList],
  },
  methods: {
    resizeHandler() {
      let eleMenu = this.$refs.menu as InstanceType<typeof ElMenu>
      let eleMainBody = this.$refs.mainBody as HTMLElement
      eleMainBody.style.width = `calc(100vw - ${getComputedStyle(eleMenu.$el).width})`
    },
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      // const first = matched[0]

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    handleOpen() {
      //key: unknown, keyPath: unknown
      // console.log(key, keyPath)
    },
    handleClose() {
      //key: unknown, keyPath: unknown
      // console.log(key, keyPath)
    },
    loginOut() {
      loginOut()
    },
    clickFull() {
      this.isCollapse = !this.isCollapse
      setTimeout(() => {
        this.resizeHandler()
      }, 0)
    },
  },
})
