import { LocalStorageKey } from '@/config/enum'

export class VuexStateKey {
  static G = 'G'
  static userInfo = LocalStorageKey.USER_INFO
  static auths = 'auths'
  static menuList = 'menuList'
}
export class VuexMutationKey {
  static updateUserInfo = 'updateUserInfo'
  static updateAuths = 'updateAuths'
  static updateMenuList = 'updateMenuList'
}
