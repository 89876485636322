import { AuthTree } from '@/api/user'
import G from '@/config/config'
import { settingRoutes } from '@/router'
import { getUserInfo } from '@/utils/special/getUserInfo'
import { UserInfo } from '@/vo/UserInfo'
import { RouteRecordRaw } from 'vue-router'
import { createStore } from 'vuex'
import { VuexMutationKey, VuexStateKey } from './vuexEnum'
type G = typeof G
const store = createStore({
  state: {
    [VuexStateKey.G]: G,
    [VuexStateKey.userInfo]: getUserInfo(),
    [VuexStateKey.auths]: Array<AuthTree>(),
    [VuexStateKey.menuList]: Array<RouteRecordRaw>(),
  },
  mutations: {
    [VuexMutationKey.updateUserInfo](state, userInfo: UserInfo) {
      console.log('updateUserInfo', userInfo)
      state[VuexStateKey.userInfo] = userInfo === undefined ? undefined : Object.assign({}, userInfo)
    },
    [VuexMutationKey.updateAuths](state, auths: AuthTree[]) {
      console.log('updateAuths', auths)
      state[VuexStateKey.auths] = auths
      settingRoutes()
    },
    [VuexMutationKey.updateMenuList](state, menuList: Array<RouteRecordRaw>) {
      console.log('updateMenuList', menuList)
      state[VuexStateKey.menuList] = menuList
    },
  },
  actions: {},
  modules: {},
})

export default store
