import { setPageTitle } from '@/utils/common/system'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Main from '@/components/Main/Main.vue'
import { VuexMutationKey, VuexStateKey } from '@/store/vuexEnum'
import store from '@/store'
import _ from 'lodash'
import { isLogin, setAuths } from '@/utils/utils'
import { apiGetAuth, AuthTree } from '@/api/user'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import { getUserInfo } from '@/utils/special/getUserInfo'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/',
  //   name: 'home',
  //   redirect: '/generic',
  //   meta: {
  //     isConstRoute: true,
  //   },
  // },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      isConstRoute: true,
      noLogin: true,
    },
  },
  {
    path: '/system',
    name: 'system',
    component: Main,
    meta: {
      title: '系统管理',
      icon: '&#xe629;',
      permission: '系统管理',
      menuShow: true,
    },
    children: [
      {
        path: '/system/user',
        name: 'user',
        component: () => import('@/views/system/User.vue'),
        meta: {
          icon: '&#xe6a2;',
          title: '用户管理',
          menuShow: true,
          permission: '用户管理',
        },
      },
      {
        path: '/system/roles',
        name: 'roles',
        component: () => import('@/views/system/Roles.vue'),
        meta: {
          icon: '&#xe687;',
          title: '角色管理',
          menuShow: true,
          permission: '角色管理',
        },
      },
    ],
  },
  {
    name: '404',
    path: '/:pathMatch(.*)',
    component: () => import('@/views/404.vue'),
    meta: {
      isConstRoute: true,
      title: '404',
      noLogin: true,
    },
  },
  {
    name: 'generic',
    path: '/',
    component: () => import('@/views/Generic.vue'),
    meta: {
      isConstRoute: true,
      title: 'generic',
      noLogin: true,
    },
  },
]

export const constRoutes = routes.filter(i => i.meta?.isConstRoute)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constRoutes,
})

router.afterEach(to => {
  NProgress.done()
  setPageTitle(to.meta.title)
})

export default router

NProgress.configure({ showSpinner: false })

let isGetedAuth = false

router.beforeEach((to, from, next) => {
  NProgress.start()
  const noLogin = to?.meta?.noLogin
  const needLogin = noLogin === void 0 ? true : !noLogin

  if ((!/404/.test(to.path) && to.name === '404') || needLogin) {
    if (isLogin()) {
      let userAuths = (store.state[VuexStateKey.auths] as unknown) as AuthTree[]
      if (!userAuths || (userAuths && userAuths.length == 0 && !isGetedAuth)) {
        let userInfo = getUserInfo()
        if (userInfo) {
          apiGetAuth(userInfo.roleId).then(authTree => {
            isGetedAuth = true
            setAuths(authTree)
            next({
              path: to.path,
              query: to.query,
            })
          })
        }
      } else {
        next()
      }
    } else {
      console.log('this route need login.')
      next({
        name: 'login',
      })
    }
  } else {
    next()
  }
})

function recursionAddRoute(routes: Array<RouteRecordRaw>, auths: AuthTree[], isTop?: boolean): Array<RouteRecordRaw> {
  let arr = Array<RouteRecordRaw>()
  if (routes && routes.length != 0) {
    routes.forEach(route => {
      if (isTop) {
        route.meta = Object.assign(
          {
            __index: 1,
          },
          route.meta
        )
      }
      let childrens = route?.children || []
      delete route.children
      let routePermission = route?.meta?.permission
      let hasPermission = routePermission === undefined ? false : true
      let auth = auths.filter(authItem => authItem.authName === route.meta?.permission)[0]
      route.children = recursionAddRoute(childrens, auth?.subAuth || [])
      if (hasPermission) {
        if (auth) {
          arr.push(route)
        }
      } else {
        arr.push(route)
      }
    })
  } else {
    return []
  }
  return arr
}

function recursionMenuList(routes: Array<RouteRecordRaw>) {
  let arr = Array<RouteRecordRaw>()
  if (routes && routes.length != 0) {
    routes.forEach(route => {
      let childrens = route?.children || []
      delete route.children
      let routeMenuShow = route?.meta?.menuShow
      let hasMenuShow = routeMenuShow === undefined ? false : true

      route.children = recursionMenuList(childrens)
      if (hasMenuShow) {
        arr.push(route)
      }
    })
  } else {
    return []
  }
  return arr
}

export function settingRoutes() {
  console.log('settingRoutes auths')
  let allRoutes = router.getRoutes()
  allRoutes.forEach(route => {
    if (!route?.meta?.isConstRoute && route.name) {
      router.removeRoute(route.name)
    }
  })
  constRoutes.forEach(route => {
    router.addRoute(route)
  })
  let auths = (store.state[VuexStateKey.auths] as unknown) as AuthTree[]
  let asyncRoute = _.cloneDeep(routes.filter(route => !route.meta?.isConstRoute))
  recursionAddRoute(asyncRoute, auths, true).forEach(route => {
    router.addRoute(route)
  })

  let menuList = Array<RouteRecordRaw>()
  let constMenuList = recursionMenuList(_.cloneDeep(constRoutes))
  let asyncMenuList = recursionMenuList(_.cloneDeep(router.getRoutes().filter(item => item.meta.__index === 1)))
  menuList.push(...constMenuList, ...asyncMenuList)
  store.commit(VuexMutationKey.updateMenuList, menuList)
  console.log('settingRoutes auths：', auths, router.getRoutes(), menuList)
}
