export class UrlQueryKey {}

export class LocalStorageKey {
  static USER_INFO = 'userInfo'
}
export class TimeKey {}
export class SessionStorageKey {}

export class EventKey {}

export class ErrorServerKey {}
