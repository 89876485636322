<template>
  <div class="main">
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        ref="menu"
        class="el-menu-vertical"
        :uniqueOpened="false"
        @close="handleClose"
        :collapse="isCollapse"
        :collapse-transition="false"
        background-color="#304156"
        text-color="#fff"
        :router="true"
        active-text-color="rgb(64, 158, 255)"
      >
        <menu-item :list="menuList"></menu-item>
      </el-menu>
    </el-scrollbar>
    <section class="main-body" ref="mainBody">
      <div class="nav">
        <div class="nav-left">
          <Hamburger :isActive="!isCollapse" @click="clickFull"></Hamburger>
          <el-breadcrumb separator-class="el-icon-arrow-right" class="nav-breadcrumb">
            <!-- <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item> -->
            <el-breadcrumb-item v-for="item in levelList" :key="item.path">
              <span>
                {{ item.meta.title }}
              </span>
              <!--   <span v-if="item.redirect === 'noRedirect' || index == levelList.length - 1" class="no-redirect">{{ item.meta.title }}</span>
              <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a> -->
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <el-dropdown trigger="click">
          <div class="nav-actions el-dropdown-link">
            <el-avatar :src="'https://homework-webfront.oss-cn-beijing.aliyuncs.com/MiniproStudent/images/common/logo.png?imageView2/1/w/80/h/80'"></el-avatar
            ><i class="el-icon-arrow-down el-icon--right"></i>
            <div style="width: 15px"></div>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="loginOut"><i class="iconfont">&#xe618;</i> 退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="main-content">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import store from '@/store'
import { VuexStateKey } from '@/store/vuexEnum'
import { defineComponent } from '@vue/runtime-core'
import Hamburger from './components/Hamburger.vue'
import MenuItem from './components/MenuItem'
import { loginOut } from '@/utils/utils'
import { ElMenu } from 'element-plus'
import { RouteLocationMatched } from 'vue-router'

export default defineComponent({
  components: {
    [Hamburger.name]: Hamburger,
    [MenuItem.name]: MenuItem,
  },
  data() {
    return {
      isCollapse: false,
      levelList: Array<RouteLocationMatched>(),
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },
  created() {
    this.getBreadcrumb()
  },
  mounted() {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  computed: {
    menuList: () => store.state[VuexStateKey.menuList],
  },
  methods: {
    resizeHandler() {
      let eleMenu = this.$refs.menu as InstanceType<typeof ElMenu>
      let eleMainBody = this.$refs.mainBody as HTMLElement
      eleMainBody.style.width = `calc(100vw - ${getComputedStyle(eleMenu.$el).width})`
    },
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      // const first = matched[0]

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
    },
    handleOpen() {
      //key: unknown, keyPath: unknown
      // console.log(key, keyPath)
    },
    handleClose() {
      //key: unknown, keyPath: unknown
      // console.log(key, keyPath)
    },
    loginOut() {
      loginOut()
    },
    clickFull() {
      this.isCollapse = !this.isCollapse
      setTimeout(() => {
        this.resizeHandler()
      }, 0)
    },
  },
})
</script>

<style lang="scss" scoped>
.main {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .main-body {
    /* flex-grow: 1;
    flex-shrink: 1; */
    .nav {
      height: 60px;
      background: #f1f3f4;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .nav-left {
        display: flex;
        align-items: center;
        .nav-breadcrumb {
          margin-left: 16px;
        }
      }
      .nav-actions {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }

    .main-content {
      width: 100%;
      max-height: calc(100% - 60px);
      overflow: auto;
    }
  }
}
</style>
<style lang="scss">
$menuText: #bfcbd9;
$menuActiveText: #409eff;
$subMenuActiveText: #f4f4f5; //https://github.com/ElemeFE/element/issues/12951

$menuBg: #304156;
$menuHover: #263445;

$subMenuBg: #1f2d3d;
$subMenuHover: #001528;

$sideBarWidth: 210px;
.main {
  .scrollbar-wrapper {
    overflow-x: hidden !important;
  }

  .el-scrollbar__bar.is-vertical {
    right: 0px;
  }

  .el-scrollbar {
    height: 100%;
    background-color: rgb(48, 65, 86);
  }

  // menu hover
  .submenu-title-noDropdown,
  .el-submenu__title {
    i {
      color: rgb(191, 203, 217);
    }
    &:hover {
      background-color: $menuHover !important;
    }
  }

  .el-menu-item i {
    color: rgb(191, 203, 217);
  }

  .is-active > .el-submenu__title {
    color: $subMenuActiveText !important;
  }

  .el-menu-vertical {
    min-width: $sideBarWidth;
    width: 100%;
  }

  .el-menu--collapse {
    min-width: 100%;
  }

  & .nest-menu .el-submenu > .el-submenu__title,
  & .el-submenu .el-menu-item {
    background-color: $subMenuBg !important;
    min-width: $sideBarWidth;
    &:hover {
      background-color: $subMenuHover !important;
    }
  }
}
</style>
