
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    /*  toggleClick() {
      this.$emit('toggleClick')
    }, */
  },
})
