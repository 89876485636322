import G from '@/config/config'

export const setPageTitle = function(title: string | undefined): void {
  document.title = title || G.defaultTitle
}

export function onBackReloadPage() {
  let isPageHide = false
  window.addEventListener('pageshow', function() {
    if (isPageHide) {
      window.location.reload()
    }
  })
  window.addEventListener('pagehide', function() {
    isPageHide = true
  })
}
