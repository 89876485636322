import { NodeEnv } from './NodeEnv'
const ENV: NodeEnv = (process.env as unknown) as NodeEnv
const G = {
  isMock: false,
  ENV: ENV.NODE_ENV,
  platform: ENV.VUE_APP_PLATFORM,
  defaultTitle: ' ',
}

export default G
